import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import TopContent from '../../../Shared/Components/TopContent';
import { fetchSingleDeviceOrder } from '../../../redux/slices/DeviceOrderProcessSlice';
import { Input, InputInnerSymbol } from '../../../Shared/Components/Form/Input';

function DeviceOrderView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const singleDeviceOrder = useSelector((state) => state.deviceOrderProcess.singleDeviceOrder);
  const navigate = useNavigate();

  const backButton = () => {
    navigate('../processed');
  };
  useEffect(() => {
    dispatch(fetchSingleDeviceOrder(params?.id));
  }, []);

  return (
    <div className="main-container">
      <TopContent
        label={[t('deviceOrder'), singleDeviceOrder?.accountName]}
        buttonClass="success-button"
        click={backButton}
      />
      <div className="content-area">
        <div className="device-order-form">
          <div className="device-order-form-header">
            <h1>{t('customerInformation')}</h1>
            <div>{t('orderId')} : {singleDeviceOrder.orderId}</div>
          </div>
          <div className="single-row">
            <Input value={singleDeviceOrder?.accountName} readonly />
          </div>
        </div>
        <div className="device-order-form">
          <div className="device-order-form-header">
            <h1>{t('rentalInformation')}</h1>
          </div>
          <div className="rental-devices-form">
            {
              singleDeviceOrder?.rentalDevice?.map((device) => (
                <div className="rental-device-row">
                  <Input value={device?.device_catalog?.name} readonly label={t('deviceName')} />
                  <Input value={`${device.tenure} Months`} readonly label={t('rentalTenure')} />
                  <Input value={device.quantity} readonly label={t('quantity')} />
                  <InputInnerSymbol
                    value={device.price}
                    readonly
                    symbol={singleDeviceOrder?.currencySymbol}
                    label={t('price')}
                  />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceOrderView;
