import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import profile from '../../../assets/images/download.jpg';
import TopContent from '../../../Shared/Components/TopContent';
import { Input } from '../../../Shared/Components/Form/Input';

import companyApi from '../../../utils/api/company';
import { checkPermissionAllow } from '../../../utils/helpers';

function CompanyAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const companyData = useSelector((state) => state.company.company);
  const [value, setValue] = useState(companyData.find((each) => each.id === params?.id));

  useEffect(() => {
    if (!value) {
      companyApi.getSingleCompany(params?.id)
        .then((res) => {
          setValue(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [params]);

  const editCompany = () => {
    navigate(`../company-edit/${params?.id}`);
  };

  return (
    <div className="main-container">
      <TopContent
        label={[t('company'), value?.name]}
        click={editCompany}
        button={checkPermissionAllow('editCompany')}
        buttonLabel={t('editCompany')}
        buttonClass="default-button"
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area-form-1">
            <div className="company-form">
              <div className="profile-upload">
                <label htmlFor="file" className="upload-click">
                  <img src={value?.signedUrl || profile} alt="profile" />
                </label>
              </div>
              <div className="details-upload">
                <div className="company-info">
                  <h2>{t('companyInformation')}</h2>
                  <div className="form-field">
                    <Input
                      type="text"
                      label={t('companyName')}
                      name="name"
                      value={value?.name}
                      readonly
                    />
                    <Input
                      type="text"
                      label={t('taxId')}
                      name="tax_id"
                      value={value?.tax_id}
                      readonly
                    />
                  </div>
                  <div className="form-field">
                    <Input
                      type="tel"
                      label={t('phoneNumber')}
                      name="phone_number"
                      value={value?.admin_user_id?.phone_number}
                      readonly
                    />
                    <Input
                      type="email"
                      label={t('mailId')}
                      name="email"
                      value={value?.email}
                      readonly
                    />
                  </div>
                  <Input
                    type="text"
                    label={t('address')}
                    readonly
                    name="address"
                    value={value?.address}
                  />
                  <div className="form-field">
                    <Input
                      type="text"
                      label={t('country')}
                      name="user_name"
                      value={value?.country}
                      readonly
                      placeholder="Enter Username"
                    />
                    <Input
                      type="text"
                      label={t('state')}
                      name="state"
                      value={value?.state}
                      readonly
                      placeholder="State"
                    />
                  </div>
                  <div className="form-field">
                    <Input
                      type="text"
                      label={t('city')}
                      name="city"
                      value={value?.city}
                      readonly
                    />
                    <Input
                      type="text"
                      label={t('pinCode')}
                      name="pincode"
                      value={value?.pincode}
                      readonly
                    />
                  </div>
                </div>
                <div className="user-info">
                  <h2>{t('userInformation')}</h2>
                  <div className="form-field">
                    <Input
                      type="text"
                      label={t('fullName')}
                      name="user_full_name"
                      value={value?.admin_user_id?.name}
                      readonly
                      placeholder={t('enterFullName')}
                    />
                    <Input
                      type="text"
                      label={t('username')}
                      name="user_name"
                      value={value?.admin_user_id?.user_name}
                      readonly
                      placeholder={t('enterUsername')}
                    />
                  </div>
                  <div className="form-field">
                    <Input
                      type="text"
                      label={t('role')}
                      name="role"
                      value={value?.account_type === 'cms' ? t('cmsAdmin') : t('distributorAdmin')}
                      readonly
                    />
                    <Input
                      type="text"
                      label={t('mailId')}
                      name="user_email"
                      value={value?.admin_user_id?.email}
                      readonly
                      placeholder={t('enterUserMailId')}
                    />
                    {/* <Input
                        type="password"
                        label="Password"
                        name="password"
                        value={value.password}
                        readonly
                        placeholder="Enter Password"
                      /> */}
                  </div>
                </div>
                {
                  value?.account_type === 'distributor' && (
                    <div className="user-info">
                      <h2>{t('revenueInformation')}</h2>
                      <div className="form-field">
                        <Input
                          type="text"
                          label={t('distributorMargin%')}
                          readonly
                          name="margin_percentage"
                          value={value.margin_percentage}
                          placeholder={t('enterRevenue%')}
                        />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyAdd;
