import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPendingSubscription,
  setTableFilterData,
  setFilter,
} from '../../../redux/slices/PendingSubscriptionSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import more from '../../../assets/icons/more.png';
import DropDown from '../../../Shared/Components/DropDown';
import DataTable from '../../Table';
import SubscriptionView from './SubscriptionView';
import subscription from '../../../utils/api/subscription';
import Success from '../../../Shared/Components/Success';
import DeleteModal from '../../../Shared/Components/DeleteModal';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { checkPermissionAllow } from '../../../utils/helpers';
import height from '../../../utils/size-variables';

function Pending() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    pendingSubscription,
    pageCount,
    totalPageCount,
    status,
    filterPending,
    tableFilterDataPending,
    totalDataCountPending,
  } = useSelector((state) => state.pendingSubscription);
  const [buttonStatus, setButtonStatus] = useState('');
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [modalView, setModalView] = useState({});
  const [notificationPopup, setNotificationPopup] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const viewFunction = (singleSubscription) => {
    setButtonStatus('success');
    setNotificationPopup(false);
    setModalView({
      title: t('subscriptionView'),
      content: <SubscriptionView
        data={singleSubscription}
      />,
    });
  };

  const deleteFunction = (id) => {
    setSubscriptionId(id);
    setDeleteModal(true);
  };

  const getSubscriptions = (counts, search) => {
    dispatch(fetchPendingSubscription({ page: counts, ...search }));
  };

  // Cancel the existing subscription
  const cancelFunction = () => {
    setDeleteModal(false);
    subscription.deleteSubscription(subscriptionId)
      .then(() => {
        setNotificationPopup(true);
        setModalView({ content: <Success message={t('pendingSubscriptionCancelledSuccess')} /> });
        setButtonStatus('success');
        setTimeout(() => {
          setButtonStatus('');
          getSubscriptions(1, {});
        }, 3000);
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  };

  const handleScroll = () => {
    getSubscriptions(pageCount + 1, filterPending);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewFunction,
      key: 'update',
      sendFullDetails: true,
      enable: checkPermissionAllow('getSubscription'),
    },
    {
      name: t('cancel'),
      url: '',
      function: deleteFunction,
      key: 'cancel',
      enable: checkPermissionAllow('cancelSubscription'),
    },
  ];

  const columns = [
    {
      header: t('product'),
      accessorKey: 'product',
    },
    {
      header: t('planType'),
      accessorKey: 'plan_type',
    },
    {
      header: t('planName'),
      accessorKey: 'name',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row.original.plan_name,
    },
    {
      header: t('account'),
      filterFn: 'commonFIlterFn',
      accessorKey: 'company_name',
    },
    {
      header: t('orderId'),
      accessorKey: 'order_id',
    },
    {
      header: t('price'),
      accessorKey: 'total_price',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row.original.total_price_toShow,
    },
    {
      header: t('startDate'),
      accessorKey: 'start_date',
      enableColumnFilter: false,
    },
    {
      header: t('endDate'),
      accessorKey: 'end_date',
      enableColumnFilter: false,
    },
    {
      header: '',
      accessorKey: 'action',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getSubscriptions(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    getSubscriptions(1, filterPending);
  }, []);

  return (
    <div className="content-area">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        modalView={modalView}
        notification={notificationPopup}
        closeModal={() => setButtonStatus('')}
      />
      <DeleteModal
        title={t('cancelSubscriptionConfirmation')}
        subTitle={t('cancelSubscriptionMessage')}
        buttonLabel={t('yes')}
        displayLabelNo
        status={deleteModal}
        setOpen={setDeleteModal}
        okFunction={cancelFunction}
      />
      <div className="my-content">
        <div className="my-content-area">
          <DataTable
            header={columns}
            value={pendingSubscription}
            status={status}
            totalPageCount={totalPageCount}
            pageCount={pageCount}
            scrollData={handleScroll}
            totalDataCount={totalDataCountPending}
            reduxColumnFiltersSorting={tableFilterDataPending}
            onFilterOrSortingChange={onFilterOrSortingChange}
            setColumnVisibility={() => {}}
            columnVisibility=""
            isFirstRender={isFirstRender}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enable={Object.keys(filterPending).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
            height={height.tableHeight}
          />
        </div>
      </div>
    </div>
  );
}
Pending.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

Pending.defaultProps = {
  row: null,
};
export default Pending;
