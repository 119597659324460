/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';

import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { fetchActiveSubscription } from '../../../redux/slices/ActiveSubscriptionSlice';

import Button from '../../../Shared/Components/Button';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { InputIncreaseDecrease } from '../../../Shared/Components/Form/Input';

import subscription from '../../../utils/api/subscription';
import { currencySymbol } from '../../../utils/helpers';

function AddOnCountEdit(props) {
  const { t } = useTranslation();
  const {
    singleSubscription,
    setOpen,
  } = props;
  const dispatch = useDispatch();
  const currency = currencySymbol(singleSubscription?.company?.currency || 'USD');
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState(false);

  const headerContent = [
    {
      label: t('orderID:'),
      value: singleSubscription.order_id,
    },
    {
      label: t('account:'),
      value: singleSubscription.company_name,
    },
  ];

  const [addOnStorage, setAddOnStorage] = useState(singleSubscription.addon_storage);
  const [buttonState, setButtonState] = useState('disable');
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [storagePrice, setStoragePrice] = useState({
    totalPrice: 0.00,
    singleStoragePrice: 0.00,
    changeStorageCount: 0,
  });

  // Function to update the price to show
  const handleInputChange = (name, value) => {
    setLoading(true);
    setAddOnStorage(value);
    let changeInStorageCount = 0;
    if (singleSubscription?.addon_storage) {
      changeInStorageCount = value - singleSubscription.addon_storage;
    } else {
      changeInStorageCount = value;
    }
    const data = {
      planId: singleSubscription?.plan?._id,
      tenure: singleSubscription?.subscription?.tenure,
      start_date: singleSubscription?.start_date,
      end_date: singleSubscription?.end_date,
      currency: singleSubscription?.currency || 'USD',
      storageCountChange: changeInStorageCount,
    };
    // Api call to handle storage price to display
    subscription.calculatePrice(data)
      .then((res) => {
        setLoading(false);
        setStoragePrice({
          totalPrice: res?.data?.additionalStoragePrice,
          singleStoragePrice: res?.data?.additionalStoragePrice !== 0
            ? ((res.data.additionalStoragePrice || 0) / changeInStorageCount).toFixed(2)
            : 0,
          changeStorageCount: changeInStorageCount,
        });
      })
      .catch((err) => {
        setLoading(false);
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const paymentOptionChange = (event) => {
    setPaymentType(event.target.value === 'online');
  };

  // Update functionality
  const submitAddOnEdit = () => {
    setButtonState('loading');
    const addOnData = {
      id: singleSubscription.id,
      plan: singleSubscription.new_plan_id,
      deviceCount: singleSubscription.device.count,
      create_payment_link: paymentType,
      addOnStorage,
    };
    subscription.editSubscriptionAddOn(addOnData)
      .then(() => {
        setButtonState('success');
        setTimeout(() => {
          setOpen(false);
          setButtonState('disable');
          dispatch(fetchActiveSubscription({ page: 1 }));
        }, 3000);
      })
      .catch((err) => {
        setButtonState('enable');
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  // To enable and disable the update button
  useEffect(() => {
    if (addOnStorage < singleSubscription.addon_storage) {
      setShowAlertMessage(true);
    } else {
      setShowAlertMessage(false);
    }

    if (addOnStorage !== singleSubscription.addon_storage) {
      setButtonState('enable');
    } else {
      setButtonState('disable');
    }
  }, [addOnStorage]);

  return (
    <div className="popup-wrap">
      <ModalBox
        status={buttonState === 'success'}
        setOpen={setButtonState}
        modalView={{ content: <Success message={t('addonEdited')} /> }}
        notification
      />
      <div className="popup-header">
        {headerContent.map((content) => (
          <div className="header-details">
            <h2>{content.label}</h2>
            <p>{content.value}</p>
          </div>
        ))}
      </div>
      <div className="popup-container">
        <div className="input-with-price">
          <InputIncreaseDecrease
            icon={' '}
            label={t('addonStorage')}
            type="number"
            name="addon_storage"
            required={false}
            value={addOnStorage}
            change={!loading && handleInputChange}
            placeholder="0"
            min={0}
          />
          <p>
            {storagePrice.changeStorageCount}
            * {currency}{storagePrice.singleStoragePrice || 0}/GB =
            {currency}{storagePrice.totalPrice}
          </p>
        </div>
        <p
          className="alert-message"
          style={{
            '--opacity': showAlertMessage ? '1' : '0',
          }}
        >
          The amount for the decreased add-on will be reflected in the customer&apos;s wallet.
        </p>
        <h1 className="payment-label">{t('paymentMode')}</h1>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={paymentOptionChange}
          defaultValue="offline"
        >
          <FormControlLabel
            value="online"
            control={<Radio />}
            label={t('online')}
            disabled
            sx={{
              '& .MuiTypography-root': {
                fontFamily: 'Poppins',
              },
            }}
          />
          <FormControlLabel
            value="offline"
            control={<Radio />}
            label={t('offline')}
            sx={{
              '& .MuiTypography-root': {
                fontFamily: 'Poppins',
              },
            }}
          />
        </RadioGroup>
        {
          paymentType && (
            <p className="payment-message">{t('onlinePaymentMessage')}</p>
          )
        }
        <div className="plan-btn-wrap">
          <Button
            label={t('back')}
            classes="default-button"
            click={() => setOpen(false)}
          />
          <Button
            label={t('updateAddon')}
            loading={buttonState === 'loading'}
            classes={
              buttonState === 'loading'
                ? 'success-button loading-btn'
                : 'success-button'
            }
            click={submitAddOnEdit}
            disabled={buttonState === 'disable'}
          />
        </div>
      </div>
    </div>
  );
}

AddOnCountEdit.propTypes = {
  singleSubscription: PropTypes.arrayOf.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default AddOnCountEdit;
