import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import TopContent from '../../../Shared/Components/TopContent';
import { Input } from '../../../Shared/Components/Form/Input';
import Button from '../../../Shared/Components/Button';

import profile from '../../../assets/images/download.jpg';
import SelectBox from '../../../Shared/Components/Form/SelectBox';

import userApi from '../../../utils/api/users';
import { singleUsers, updateUserDetails } from '../../../redux/slices/UsersSlice';
import { fetchRolesListAll } from '../../../redux/slices/RolesSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { emailValidation, nameValidation } from '../../../Shared/Components/Form/Validation';
import { acceptedImageTypes, fileUploadValidation } from '../../../utils/helpers';

function UserEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const rolesData = useSelector((state) => state.roles.rolesAllList);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState({
    id: '',
    name: '',
  });
  const [buttonStatus, setButtonStatus] = useState('');

  const users = useSelector((state) => state.users.users);
  const dataValue = useSelector((state) => state.users.singleUsers);
  const [value, setValue] = useState({});
  const [image, setImage] = useState();

  const addPlaylist = () => {
    setImage('');
    localStorage.removeItem('value');
    navigate('../');
  };

  const submit = () => {
    if (nameValidation(1, 100, value?.name)
      && nameValidation(1, 100, value?.user_name)
      && emailValidation(value?.email)
    ) {
      setButtonStatus('loading');
      const props = {
        id: value?.id,
        email: value?.email,
        name: value?.name,
        role: selectedRole.id,
        user_name: value?.user_name,
        profile_image: value.image,
      };
      userApi.updateUser(props).then((response) => {
        setButtonStatus('success');
        setTimeout(() => {
          setButtonStatus('');
          dispatch(updateUserDetails(response.data));
          navigate('../');
        }, 3000);
      }).catch((error) => {
        setButtonStatus('');
        dispatch(setErrorNotification(error?.response?.data));
      });
    }
  };

  const onChangePicture = (e) => {
    const maxFileSizeInMB = 1;
    const checkFileValidation = fileUploadValidation(e.target.files[0], maxFileSizeInMB, 'image');
    if (checkFileValidation === '') {
      setValue({ ...value, image: e.target.files[0] });
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      dispatch(setErrorNotification({ message: checkFileValidation }));
    }
  };

  const handleChange = (name, changeValue) => {
    if (changeValue) {
      if (name === 'role') {
        setSelectedRole({
          id: changeValue.id,
          name: changeValue.label,
        });
      } else if (name === 'location') {
        setValue({ ...value, location: changeValue.id });
      }
    }
    if (name !== 'role') {
      setValue({ ...value, [name]: changeValue });
    }
  };

  useEffect(() => {
    if (users.length !== 0) {
      dispatch(singleUsers(params?.id));
      dispatch(fetchRolesListAll());
      setValue(dataValue);
      setImage(dataValue?.signedUrl);
      setSelectedRole({
        id: dataValue?.role?.id,
        name: dataValue?.role?.name,
      });
    } else {
      navigate('../');
    }
  }, [params]);

  useEffect(() => {
    setRoles(rolesData?.map((val) => ({ ...val, label: val.name })));
  }, [rolesData]);

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        modalView={{ content: <Success message={t('userUpdatedSuccess')} /> }}
        notification
      />
      <TopContent
        label={[t('users'), dataValue?.name]}
        button={false}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <div className="user-form-wrap">
              <div className="profile-upload" style={{ height: '100%' }}>
                <label htmlFor="file" className="upload-click">
                  <img src={image || profile} alt="profile" />
                  {!image ? <p>{t('imageFormat')}</p> : <p><span>{t('changeProfile')}</span></p>}
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={onChangePicture}
                  style={{ display: 'none' }}
                  accept={acceptedImageTypes}
                />
              </div>
              <div className="user-form">
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('fullName')}
                    type="text"
                    name="name"
                    required
                    value={value?.name}
                    change={handleChange}
                    placeholder={t('enterName')}
                    error={!nameValidation(1, 100, value?.name)}
                    errorMessage={t('nameValidation')}
                  />
                  <Input
                    icon={' '}
                    label={t('username')}
                    type="text"
                    name="user_name"
                    required
                    value={value?.user_name}
                    change={handleChange}
                    placeholder={t('enterUsername')}
                    error={!nameValidation(1, 100, value?.user_name)}
                    errorMessage={t('userNameValidation')}
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('mailId')}
                    type="text"
                    name="email"
                    required
                    value={value?.email}
                    change={handleChange}
                    placeholder={t('enterMailId')}
                    error={!emailValidation(value?.email)}
                    errorMessage={t('invalidEmail')}
                  />
                  <SelectBox
                    label={t('role')}
                    name="role"
                    required
                    value={selectedRole?.name}
                    onchange={handleChange}
                    lists={roles}
                    id={t('selectUserRole')}
                  />
                </div>
                {/* <SelectBox
                    multiple
                    label="Store"
                    name="location"
                    required
                    value={value?.location}
                    onchange={handleChange}
                    lists={location}
                    id="select-location"
                    placeholder="Select Store"
                  /> */}
                <div className="form-button-group">
                  <div className="form-button">
                    <Button label={t('back')} click={addPlaylist} classes="default-button" />
                  </div>
                  <div className="form-button">
                    <Button
                      label={buttonStatus === 'loading' ? t('updating') : t('update')}
                      click={submit}
                      classes={buttonStatus === 'loading' ? 'success-button loading-btn' : 'success-button'}
                      loading={buttonStatus === 'loading'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserEdit;
