import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import cookies from 'js-cookie';

import { Input1 } from '../../Shared/Components/Form/Input';
import Button from '../../Shared/Components/Button';
import { Recaptcha, setCookieStorageItem } from '../../utils/helpers';

import userIcon from '../../assets/icons/user1.png';
import passwordIcon from '../../assets/icons/password1.png';
import languageIcon from '../../assets/icons/language.png';
import loginImage from '../../assets/images/adminPortalLogo.jpg';

import auth from '../../utils/api/auth';
import DropDownMenu from '../../Shared/Components/Form/DropDownMenu';

function Login() {
  const { t } = useTranslation();
  const { VerifyRecaptcha } = Recaptcha();
  const [value, setValue] = useState({});
  const [disable, setDisable] = useState(true);
  const [logInCred, setLogInCred] = useState(null);
  const [errorNotification, setErrorNotification] = useState({
    errorMessage: 'An unexpected error occurred.',
    showErrorMessage: false,
  });
  const [logInButtonText, setLogInButtonText] = useState('login');
  const [logInButtonLoading, setLogInButtonLoading] = useState(false);
  const navigate = useNavigate();
  const currentLanguageCode = cookies.get('i18next');
  const changeHandle = (name, changeValue) => {
    setValue({ ...value, [name]: changeValue });
  };
  const handleLanguage = (code) => {
    i18next.changeLanguage(code);
    window.location.reload();
  };
  const menuList = [
    {
      code: 'en',
      name: 'English',
      function: handleLanguage,
      disabled: currentLanguageCode === 'en',
    },
    {
      code: 'ar',
      name: 'عربي (Arabic)',
      function: handleLanguage,
      disabled: currentLanguageCode === 'ar',
    },
  ];

  const submit = async () => {
    setErrorNotification({
      errorMessage: '',
      showErrorMessage: false,
    });
    try {
      const recaptchaToken = await VerifyRecaptcha('login');
      const loginPayload = { ...value, recaptchaToken };
      setLogInButtonLoading(true);
      if (logInButtonText === 'login') {
        await auth.login(loginPayload).then((response) => {
          setLogInCred(response?.data);
          setLogInButtonLoading(false);
          setLogInButtonText('sendOtp');
        });
      }
      if (logInButtonText === 'sendOtp') {
        const payload = {
          email: value?.email,
          recaptchaToken,
        };
        await auth.sendLogInOtp(payload).then(() => {
          setLogInButtonLoading(false);

          setLogInButtonText('submit');
        });
      }
      if (logInButtonText === 'submit') {
        const payload = {
          email: value?.email,
          logInOtp: value?.logInOtp,
          recaptchaToken,
        };
        await auth.verifyEmail(payload).then(() => {
          const userData = {
            user: logInCred?.user,
            permissions: logInCred?.permissions,
          };
          setCookieStorageItem('secure-auth-access', logInCred?.tokens?.access?.token);
          setCookieStorageItem('secure-auth-refresh', logInCred?.tokens?.refresh?.token);
          localStorage.setItem('data', JSON.stringify(userData));
          navigate('/super-admin/company');
        });
      }
    } catch (error) {
      setLogInButtonLoading(false);
      if (error?.response?.data?.message) {
        setErrorNotification({
          errorMessage: error?.response?.data?.message,
          showErrorMessage: true,
        });
      } else {
        setErrorNotification({
          errorMessage: 'An unexpected error occurred',
          showErrorMessage: true,
        });
      }
    }
  };

  useEffect(() => {
    setErrorNotification({
      ...errorNotification,
      showErrorMessage: false,
    });
    if (value.email && value.password) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  useEffect(() => {
    localStorage.removeItem('data');
  }, []);
  const handleResend = async () => {
    setErrorNotification({
      errorMessage: '',
      showErrorMessage: false,
    });
    setLogInButtonLoading(true);
    setLogInButtonText('sendOtp');
    const recaptchaToken = await VerifyRecaptcha('login');
    const payload = {
      email: value?.email,
      recaptchaToken,
    };
    await auth.sendLogInOtp(payload).then(() => {
      setLogInButtonText('submit');
      setLogInButtonLoading(false);
    })
      .catch((error) => {
        setLogInButtonLoading(false);
        if (error?.response?.data?.message) {
          setErrorNotification({
            errorMessage: error?.response?.data?.message,
            showErrorMessage: true,
          });
          setLogInButtonText('sendOtp');
        }
      });
  };
  return (
    <div className="login-wrap">
      <div className="login-banner">
        <img src={loginImage} className="login-image" alt="" />
      </div>
      <div className="login-form-wrap">
        <div className="login-form-container">
          <div className="login-form-header">
            <h1>{t('welcomeBack')}</h1>
            {logInButtonText === 'login' && <h4>{t('enterCredentials')} </h4>}
            {logInButtonText === 'sendOtp' && <h4>{t('enterYourEmailForOtp')} </h4>}
            {logInButtonText === 'submit' && <h4>{t('enterThe-6Digit')} </h4>}
            <p
              className="error-message"
              style={{
                '--opacity': errorNotification.showErrorMessage ? '1' : '0',
              }}
            >
              {errorNotification.errorMessage}
            </p>
          </div>
          <div className="login-form">
            <Input1
              icon={userIcon}
              label={t('username')}
              type="text"
              required={false}
              name="email"
              change={changeHandle}
            />
            {logInButtonText === 'login'
              && (
                <Input1
                  icon={passwordIcon}
                  label={t('password')}
                  type="password"
                  required={false}
                  name="password"
                  change={changeHandle}
                  ar={currentLanguageCode}
                />
              )}
            {logInButtonText === 'submit'
              && (
                <Input1
                  icon={passwordIcon}
                  label={t('code')}
                  type="password"
                  required={false}
                  name="logInOtp"
                  change={changeHandle}
                  ar={currentLanguageCode}
                />
              )}
            {logInButtonText === 'login'
              && <span className="forget-password"><Link to="/forgot-password">Forgot Password?</Link></span>}
            <Button
              label={t(logInButtonText)}
              click={submit}
              loading={logInButtonLoading}
              classes="success-button"
              disabled={disable}
            />
            {logInButtonText === 'submit'
              && (
                <div className="resend">
                  <p>{t('dontSeeACode?')}</p>
                  <span onClick={handleResend} role="none">{t('resendToEmail')}</span>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className={currentLanguageCode === 'ar'
        ? 'language-wrap-absolute-ar'
        : 'language-wrap-absolute'}
      >
        <p>{currentLanguageCode === 'en' ? 'English' : 'عربي '}</p>
        <DropDownMenu
          dropdown="dropdown"
          icon={languageIcon}
          action={menuList}
          height="24px"
          tooltip={t('language')}
        />
      </div>
    </div>
  );
}

export default Login;
