/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import company from '../../utils/api/company';
import { setErrorNotification } from './NotificationSlice';
import { changeDateTimeFormat } from '../../pages/Global/timeFormat';

export const fetchCompanyList = createAsyncThunk(
  'company/fetchIndirectCompany',
  async (data, { dispatch }) => {
    const response = await company.getIndirectCompanies(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        id: val?._id,
        city_state: `${val?.city}, ${val?.state}`,
        createdAt: changeDateTimeFormat(val?.createdAt),
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  indirectCompany: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'success',
  singleCompany: {},
  filter: {},
  tableFilterData: {},
};

export const indirectCompanySlice = createSlice({
  name: 'indirectCompany',
  initialState,
  reducers: {
    singleCompany: (state, action) => {
      state.singleCompany = state.company.find((val) => val.id === action.payload);
    },
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetIndirectCompanyState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchCompanyList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCompanyList.fulfilled, (state, action) => {
        // Add user to the state array
        if (action?.payload?.code === 200) {
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages > 0 ? action.payload.totalPages : 1;
          const data = action.payload.results;
          if (action.payload.page === 1) {
            state.indirectCompany = data;
          } else {
            state.indirectCompany.push(...data);
          }
        }
        state.status = 'success';
      });
  },
});

export const {
  singleCompany,
  setTableFilterData,
  setFilter,
  resetIndirectCompanyState,
  setIndirectFilter,
  setIndirectTableFilterData,
} = indirectCompanySlice.actions;

export default indirectCompanySlice.reducer;
