import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import TopContent from '../../../Shared/Components/TopContent';
import { Input } from '../../../Shared/Components/Form/Input';
import Button from '../../../Shared/Components/Button';

import rolesApi from '../../../utils/api/roles';

import { singleRoles, fetchPermissionList, updateRoleDetails } from '../../../redux/slices/RolesSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { nameValidation } from '../../../Shared/Components/Form/Validation';

function RolesEdit() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [roleName, setRoleName] = useState();
  const [selected, setSelected] = useState([]);
  const [value, setValue] = useState({
    permissions: [],
  });
  const [disable, setDisable] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');
  const { permissions, roles, singleRoles: rolesData } = useSelector((state) => state.roles);

  const getPermissions = () => {
    if (permissions.length === 0) {
      dispatch(fetchPermissionList());
    }
  };

  useEffect(() => {
    if (roles.length === 0) {
      navigate('../');
    }
    getPermissions();
    dispatch(singleRoles(params?.id));
  }, [params]);

  useEffect(() => {
    if (rolesData) {
      setValue(rolesData);
      setRoleName(rolesData?.name);
      setSelected(rolesData?.permissions);
    }
  }, [rolesData]);

  useEffect(() => {
    if (value?.name && value?.permissions?.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  const addPlaylist = () => {
    localStorage.removeItem('value');
    navigate('../');
  };

  const submit = () => {
    if (nameValidation(1, 50, value?.name)) {
      setButtonStatus('loading');
      rolesApi.updateRole(value).then((response) => {
        setButtonStatus('success');
        setTimeout(() => {
          setButtonStatus('');
          dispatch(updateRoleDetails(response.data));
          navigate('../');
        }, 3000);
      }).catch((error) => {
        setButtonStatus('');
        dispatch(setErrorNotification(error?.response?.data));
      });
    }
  };

  const handleChange = (event) => {
    if (!selected.includes(event.target.value)) {
      setValue({
        ...value,
        permissions: [...value.permissions, event.target.value],
      });
      setSelected([...selected, event.target.value]);
    } else {
      setValue((prev) => ({
        ...prev,
        permissions: prev.permissions.filter((permission) => permission !== event.target.value),
      }));
      setSelected((prev) => prev.filter((item) => item !== event.target.value));
    }
  };

  const handleInputChange = (name, changValue) => {
    setValue({ ...value, [name]: changValue });
  };

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        modalView={{ content: <Success message={t('roleUpdatedSuccess')} /> }}
        notification
      />
      <TopContent
        label={[t('roles'), roleName]}
        button={false}
        buttonClass="success-button"
        buttonLabel="Back"
        click={addPlaylist}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area-form">
            <div className="my-content-form">
              <Input
                icon={' '}
                label={t('role')}
                type="text"
                name="name"
                required
                value={value?.name}
                change={handleInputChange}
                error={!nameValidation(1, 50, value?.name)}
                errorMessage={t('roleNameValidation')}
              />
            </div>
            <div className="my-content-permissions-list">
              <h3 className="title">{t('permissions')}</h3>
              <div className="permission-list">
                <div className="permission-lis-session">
                  {
                    permissions?.map((values) => (
                      <FormControl component="fieldset">
                        <FormLabel component="legend">{values.name}</FormLabel>
                        <FormGroup
                          aria-label="position"
                          row
                          sx={{
                            gap: 7,
                            rowGap: 2,
                          }}
                        >
                          {
                            values?.children.map((e) => (
                              <FormControlLabel
                                value={e.value}
                                control={(
                                  <Checkbox
                                    size="small"
                                    sx={{
                                      color: '##1C75BC',
                                      '&.Mui-checked': {
                                        color: '##1C75BC',
                                      },
                                    }}
                                    checked={selected.includes(e.value)}
                                    onChange={handleChange}
                                  />
                                )}
                                label={e.name}
                              />
                            ))
                          }
                        </FormGroup>
                      </FormControl>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="form-button-group">
            <div className="form-button">
              <Button label={t('back')} click={addPlaylist} classes="default-button" />
            </div>
            <div className="form-button">
              <Button
                label={buttonStatus === 'loading' ? t('updating') : t('update')}
                click={submit}
                classes={buttonStatus === 'loading' ? 'success-button loading-btn' : 'success-button'}
                disabled={disable}
                loading={buttonStatus === 'loading'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RolesEdit;
