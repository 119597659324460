import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import TopContent from '../../../Shared/Components/TopContent';
import { Input } from '../../../Shared/Components/Form/Input';
import Button from '../../../Shared/Components/Button';

import rolesApi from '../../../utils/api/roles';
import { fetchPermissionList, addRoleDetails } from '../../../redux/slices/RolesSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { nameValidation } from '../../../Shared/Components/Form/Validation';

function RolesAdd() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState({});
  const [select, selectData] = useState([]);
  const [disable, setDisable] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const { permissions } = useSelector((state) => state.roles);

  const getPermissions = () => {
    if (permissions.length === 0) {
      dispatch(fetchPermissionList());
    }
  };

  const addPlaylist = () => {
    navigate('../');
  };

  const submit = () => {
    setSubmitButtonClicked(true);
    if (nameValidation(1, 50, value?.name)) {
      setButtonStatus('loading');
      rolesApi.addRole(value).then((response) => {
        setButtonStatus('success');
        setTimeout(() => {
          setButtonStatus('');
          dispatch(addRoleDetails(response.data));
          navigate('../');
        }, 3000);
      }).catch((error) => {
        setButtonStatus('');
        dispatch(setErrorNotification(error?.response?.data));
      });
    }
  };

  const handleChange = (event) => {
    if (!select.includes(event.target.value)) {
      selectData([...select, event.target.value]);
      setValue({ ...value, permissions: [...select, event.target.value] });
    } else {
      const index = select.indexOf(event.target.value);
      if (index > -1) {
        select.splice(index, 1);
      }
      selectData([...select]);
      setValue({ ...value, permissions: [...select] });
    }
  };

  const inputHandel = (name, changValue) => {
    setValue({ ...value, [name]: changValue });
  };

  useEffect(() => {
    if (value?.name && value?.permissions?.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        modalView={{ content: <Success message={t('roleCreatedSuccess')} /> }}
        notification
      />
      <TopContent
        label={[t('roles'), t('createNewRoles')]}
        buttonClass="success-button"
        button={false}
        buttonLabel="Back"
        click={addPlaylist}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area-form">
            <div className="my-content-form">
              <Input
                icon={' '}
                label={t('role')}
                type="text"
                name="name"
                required
                value={value.name}
                change={inputHandel}
                error={!nameValidation(1, 50, value?.name) && submitButtonClicked}
                errorMessage={t('roleNameValidation')}
              />
            </div>
            <div className="my-content-permissions-list">
              <h4 className="title">{t('permissions')}</h4>
              <div className="permission-list">
                <div className="permission-lis-session">
                  {
                    permissions?.map((values) => (
                      <FormControl component="fieldset">
                        <FormLabel component="legend">{values.name}</FormLabel>
                        <FormGroup
                          aria-label="position"
                          row
                          sx={{
                            gap: 7,
                            rowGap: 2,
                          }}
                        >
                          {
                            values?.children.map((e) => (
                              <FormControlLabel
                                value={e.value}
                                control={(
                                  <Checkbox
                                    size="small"
                                    sx={{
                                      color: '#1C75BC',
                                      '&.Mui-checked': {
                                        color: '#1C75BC',
                                      },
                                    }}
                                    onChange={handleChange}
                                  />
                                )}
                                label={e.name}
                              />
                            ))
                          }
                        </FormGroup>
                      </FormControl>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="form-button-group">
            <div className="form-button">
              <Button label={t('back')} click={addPlaylist} classes="default-button" />
            </div>
            <div className="form-button">
              <Button
                label={buttonStatus === 'loading' ? t('creating') : t('create')}
                click={submit}
                classes={buttonStatus === 'loading' ? 'success-button loading-btn' : 'success-button'}
                disabled={disable}
                loading={buttonStatus === 'loading'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RolesAdd;
