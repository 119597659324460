/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';

import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

import Button from '../../../Shared/Components/Button';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { InputIncreaseDecrease } from '../../../Shared/Components/Form/Input';
import { fetchActiveSubscription } from '../../../redux/slices/ActiveSubscriptionSlice';

import subscription from '../../../utils/api/subscription';
import { currencySymbol } from '../../../utils/helpers';

function DeviceCountEdit(props) {
  const { t } = useTranslation();
  const {
    singleSubscription,
    setOpen,
  } = props;
  const dispatch = useDispatch();
  const currency = currencySymbol(singleSubscription?.company?.currency || 'USD');
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState(false);

  const headerContent = [
    {
      label: t('orderID:'),
      value: singleSubscription.order_id,
    },
    {
      label: t('account:'),
      value: singleSubscription.company_name,
    },
  ];

  const [deviceCount, setDeviceCount] = useState(singleSubscription.device.count);
  const [buttonState, setButtonState] = useState('disable');
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [devicePrice, setDevicePrice] = useState({
    totalPrice: 0.00,
    singleDevicePrice: 0.00,
    changeDeviceCount: 0,
  });

  // Function to update the price to show
  const handleInputChange = (name, value) => {
    if (!loading) {
      setLoading(true);
      setDeviceCount(value);
      const changeInDeviceCount = value - singleSubscription.device_limit;
      const data = {
        planId: singleSubscription?.plan?._id,
        tenure: singleSubscription?.subscription?.tenure,
        start_date: singleSubscription?.start_date,
        end_date: singleSubscription?.end_date,
        currency: singleSubscription?.currency || 'USD',
        deviceCountChange: changeInDeviceCount,
      };
      subscription.calculatePrice(data)
        .then((res) => {
          setLoading(false);
          setDevicePrice({
            totalPrice: res?.data?.additionalDevicePrice,
            singleDevicePrice: res?.data?.additionalDevicePrice !== 0
              ? ((res.data.additionalDevicePrice || 0) / changeInDeviceCount).toFixed(2)
              : 0,
            changeDeviceCount: changeInDeviceCount,
          });
        })
        .catch((err) => {
          setLoading(false);
          dispatch(setErrorNotification(err?.response?.data));
        });
    }
  };

  const paymentOptionChange = (event) => {
    setPaymentType(event.target.value === 'online');
  };

  // Update functionality
  const submitDeviceEdit = () => {
    setButtonState('loading');
    const deviceEditData = {
      id: singleSubscription.id,
      plan: singleSubscription.new_plan_id,
      addOnStorage: singleSubscription.addon.storage.count || 0,
      create_payment_link: paymentType,
      deviceCount,
    };
    subscription.editSubscriptionDevice(deviceEditData)
      .then(() => {
        setButtonState('success');
        setTimeout(() => {
          setOpen(false);
          setButtonState('disable');
          dispatch(fetchActiveSubscription({ page: 1 }));
        }, 3000);
      })
      .catch((err) => {
        setButtonState('enable');
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  // To enable and disable the update button
  useEffect(() => {
    if (deviceCount < singleSubscription.device.count) {
      setShowAlertMessage(true);
    } else {
      setShowAlertMessage(false);
    }

    if (deviceCount !== singleSubscription.device.count && deviceCount > 0) {
      setButtonState('enable');
    } else {
      setButtonState('disable');
    }
  }, [deviceCount]);

  return (
    <div className="popup-wrap">
      <ModalBox
        status={buttonState === 'success'}
        setOpen={setButtonState}
        modalView={{ content: <Success message={t('deviceEdited')} /> }}
        notification
      />
      <div className="popup-header">
        {headerContent.map((content) => (
          <div className="header-details">
            <h2>{content.label}</h2>
            <p>{content.value}</p>
          </div>
        ))}
      </div>
      <div className="popup-container">
        <div className="input-with-price">
          <InputIncreaseDecrease
            icon={' '}
            label={t('numberOfDevice')}
            type="number"
            name="addon_storage"
            required={false}
            value={deviceCount}
            change={handleInputChange}
            placeholder="1"
            min={1}
          />
          <p>
            {devicePrice.changeDeviceCount}
            * {currency}{devicePrice.singleDevicePrice}/{t('device')} =
            {currency}{devicePrice.totalPrice}
          </p>
        </div>
        <p
          className="alert-message"
          style={{
            '--opacity': showAlertMessage ? '1' : '0',
          }}
        >
          {t('amountAddWalletMessage')}
        </p>
        <h1 className="payment-label">{t('paymentMode')}</h1>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={paymentOptionChange}
          defaultValue="offline"
        >
          <FormControlLabel
            value="online"
            control={<Radio />}
            label={t('online')}
            disabled
            sx={{
              '& .MuiTypography-root': {
                fontFamily: 'Poppins',
              },
            }}
          />
          <FormControlLabel
            value="offline"
            control={<Radio />}
            label={t('offline')}
            sx={{
              '& .MuiTypography-root': {
                fontFamily: 'Poppins',
              },
            }}
          />
        </RadioGroup>
        {
          paymentType && (
            <p className="payment-message">{t('onlinePaymentMessage')}</p>
          )
        }
        <div className="plan-btn-wrap">
          <Button
            label={t('back')}
            classes="default-button"
            click={() => setOpen(false)}
          />
          <Button
            label={t('updateDevice')}
            loading={buttonState === 'loading'}
            classes={
              buttonState === 'loading'
                ? 'success-button loading-btn'
                : 'success-button'
            }
            click={submitDeviceEdit}
            disabled={buttonState === 'disable'}
          />
        </div>
      </div>
    </div>
  );
}

DeviceCountEdit.propTypes = {
  singleSubscription: PropTypes.arrayOf.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default DeviceCountEdit;
