import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DeleteIcon } from '../../../Shared/Components/svgIcon';
import { setSelectedOrders } from '../../../redux/slices/TransactionPendingSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import ConfirmationPopup from '../../../Shared/Components/ConfirmationPopup';

function OrderSummary(props) {
  const { t } = useTranslation();
  const { selectedOrders } = props;
  const { updatePaymentType } = useSelector((state) => state.transactionPending);
  const dispatch = useDispatch();
  const [removedId, setRemovedId] = useState('');
  const [alertPopup, setAlertPopup] = useState('');
  const headerData = [
    // {
    //   name: 'Order ID',
    //   key: 'order_id',
    // },
    {
      name: t('devicename'),
      key: 'device_name',
    },
    {
      name: t('requestType'),
      key: 'event',
    },
    {
      name: t('tenure'),
      key: 'tenure',
    },
    {
      name: t('quantity'),
      key: 'quantity',
    },
    {
      name: t('validFrom'),
      key: 'valid_from',
    },
    {
      name: t('unitPrice'),
      key: 'unit_price',
    },
    {
      name: t('price'),
      key: 'price_to_show',
    },
    {
      name: t('price'),
      key: 'subscription_total_price',
    },
  ];

  const handleOrderRemove = (orderId) => {
    setRemovedId(orderId);
    setAlertPopup('open');
  };

  const handleConfirmRemove = () => {
    const orderAfterDelete = selectedOrders.filter((order) => order.id !== removedId);
    dispatch(setSelectedOrders({ selectedOrders: orderAfterDelete, updatePaymentType }));
    setAlertPopup('close');
  };

  return (
    <div className="order-summary-wrap">
      <ModalBox
        status={alertPopup === 'open'}
        setOpen={setAlertPopup}
        modalView={{
          content: <ConfirmationPopup
            title="Are you sure you want to remove this Item?"
            description={<>This will remove the item from the summary. <br />You cannot undo this action.</>}
            onCloseFunction={() => setAlertPopup('close')}
            closeButtonLabel="Back"
            onConfirmFunction={handleConfirmRemove}
            confirmButtonLabel="Confirm"
            confirmButtonFontColor="#FFF"
            confirmButtonColor="#E8212E"
            confirmButtonHoverColor="#E8212E"
          />,
        }}
        notification
      />
      <h2>{t('orderID:')} <span>{selectedOrders[0]?.order_id}</span></h2>
      {selectedOrders?.map((order) => (
        <div className="order-summary-container">
          <div className="order-summary">
            {headerData.map((header) => {
              let value;
              if (Object.keys(order).includes(header.key) && order[header.key]) {
                value = (
                  <div className="each-detail">
                    <h3>{header.name}</h3>
                    <p>{order[header.key]}</p>
                  </div>
                );
              }
              return value;
            })}
            {selectedOrders.length > 1 && updatePaymentType === 'saas-pending'
              && (
                <div
                  className="remove-icon"
                  role="presentation"
                  onClick={() => handleOrderRemove(order.id)}
                >
                  <span>
                    <DeleteIcon color="#E8212E" height="12px" width="12px" />
                  </span>
                  <span>Remove</span>
                </div>
              )}
          </div>
        </div>
      ))}
    </div>
  );
}

OrderSummary.propTypes = {
  selectedOrders: PropTypes.arrayOf.isRequired,
};

export default OrderSummary;
