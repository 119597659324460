import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchPendingDeviceOrder,
  setTableFilterData,
  setFilter,
} from '../../../redux/slices/DeviceOrderPendingSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import more from '../../../assets/icons/more.png';
import DropDown from '../../../Shared/Components/DropDown';
import DataTable from '../../Table';
import { checkPermissionAllow } from '../../../utils/helpers';
import height from '../../../utils/size-variables';

function Pending() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    deviceOrder,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilterData,
    totalDataCount,
  } = useSelector((state) => state.deviceOrderPending);
  const [buttonStatus, setButtonStatus] = useState('');
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [rowSelection, setRowSelection] = useState({});

  const updateFunction = (id) => {
    navigate(`../view/${id}`);
  };

  const getPendingOrder = (counts, search) => {
    if (status === 'success') {
      dispatch(fetchPendingDeviceOrder({ page: counts, ...search }));
    }
  };

  const handleScroll = () => {
    getPendingOrder(pageCount + 1, filter);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: updateFunction,
      key: 'update',
      updatePayment: true,
      enable: checkPermissionAllow('getDeviceOrder'),
    },
  ];

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('account'),
      filterFn: 'commonFIlterFn',
      accessorKey: 'company_name',
      Cell: ({ row }) => row?.original?.companyName,
    },
    {
      header: t('orderId'),
      accessorKey: 'order_id',
      filterFn: 'commonFIlterFn',
      muiFilterTextFieldProps: { type: 'number' },
      Cell: ({ row }) => row?.original?.id,
    },
    {
      header: t('rentalDevices'),
      accessorKey: 'rental_device_count',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row?.original?.devices,
    },
    {
      header: '',
      accessorKey: 'action',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDown
          action={actions}
          icon={more}
          value={row?.original}
          dropdown="tableForm"
          height="15px"
          tooltip=""
        />
      ),
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getPendingOrder(1, filteredValues);
  };
  useEffect(() => {
    setIsFirstRender(false);
    getPendingOrder(1, {});
  }, []);

  return (
    <div className="content-area">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        modalView={{ content: <Success message={t('subscriptionApproveSuccess')} /> }}
        notification
        closeModal={() => { }}
      />
      <div className="my-content">
        <div className="my-content-area">
          <DataTable
            header={columns}
            value={deviceOrder}
            status={status}
            totalPageCount={totalPageCount}
            pageCount={pageCount}
            reduxColumnFiltersSorting={tableFilterData}
            onFilterOrSortingChange={onFilterOrSortingChange}
            isFirstRender={isFirstRender}
            scrollData={handleScroll}
            totalDataCount={totalDataCount}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
            height={height.tableHeight}
          />
        </div>
      </div>
    </div>
  );
}
Pending.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

Pending.defaultProps = {
  row: null,
};
export default Pending;
