import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InputIncreaseDecrease, TextArea } from '../../../Shared/Components/Form/Input';
import Button from '../../../Shared/Components/Button';

import subscriptionApi from '../../../utils/api/subscription';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function ExtendSubscription({ data, setOpen, extendSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState({
    id: data,
    on_of_days: 0,
  });
  const [disable, setDisable] = useState(true);

  const handleChange = (name, changeValue) => {
    setValue({
      ...value,
      [name]: changeValue,
    });
  };

  const back = () => {
    setOpen(false);
  };

  const submit = () => {
    subscriptionApi.addExtendSubscription(value).then(() => {
      extendSubmit();
    }).catch((error) => {
      dispatch(setErrorNotification(error?.response?.data));
    });
  };

  useEffect(() => {
    if (value.on_of_days > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  return (
    <div className="expend-subscription-form">
      <InputIncreaseDecrease
        label={t('numberOfDays')}
        type="number"
        name="on_of_days"
        required
        value={value.on_of_days ?? 0}
        change={handleChange}
        min={1}
      />
      <TextArea
        label={t('reason')}
        name="reason"
        value={value?.reason}
        change={handleChange}
      />
      <div className="button-group">
        <Button label={t('back')} click={back} classes="default-button" />
        <Button
          label={t('extend')}
          click={submit}
          classes="success-button"
          disabled={disable}
        />
      </div>
    </div>
  );
}
ExtendSubscription.propTypes = {
  data: PropTypes.objectOf().isRequired,
  setOpen: PropTypes.func.isRequired,
  extendSubmit: PropTypes.func.isRequired,
};
export default ExtendSubscription;
