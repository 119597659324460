/* eslint no-underscore-dangle: 0 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import TopContent from '../../../Shared/Components/TopContent';
import { InputDate, InputIncreaseDecrease } from '../../../Shared/Components/Form/Input';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import Button from '../../../Shared/Components/Button';
import { addDeviceSummary, addProductSummary } from '../../../redux/slices/ActiveSubscriptionSlice';
import { fetchCompanyListAll } from '../../../redux/slices/CompanySlice';
import { fetchPlansWithoutTrail } from '../../../redux/slices/PlanSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import RentalDeviceConform from './RentalDeviceConfirm';
import RentalDeviceAdd from './RentalDeviceAdd';

function Subscription() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const productSummary = useSelector((state) => state.activeSubscription.productSummary);
  const companyList = useSelector((state) => state.company.companyAll);
  const planList = useSelector((state) => state.plans.plansWithoutTrail);
  const [planData, setPlanData] = useState([]);
  const [value, setValue] = useState(productSummary);
  const [selectedDate, setSelectedDate] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');
  const [modelView, setModelVew] = useState({ content: <Success message={t('addonCreatedSuccess')} /> });
  const [pricePerDeviceInMonths, setPricePerDeviceInMonths] = useState(0);
  const [currency, setCurrency] = useState(productSummary?.currency || '');
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();
  const currentDate = new Date().toISOString().slice(0, 10);
  const backButton = () => {
    navigate('../');
    dispatch(addProductSummary({}));
    dispatch(addDeviceSummary([]));
  };

  const calculateEndDate = (startDate, months) => {
    // Calculate the end date by adding the specified number of months
    const endDate = new Date(startDate);
    endDate.setMonth(startDate.getMonth() + months);
    // Return the calculated end date
    // Get the year, month, and day
    const year = endDate.getFullYear();
    const month = `0${endDate.getMonth() + 1}`.slice(-2);
    // Adding 1 because months are zero-indexed
    const day = `0${endDate.getDate()}`.slice(-2);
    // Create the desired format: YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;
    return dayjs(formattedDate);
  };

  const handleChange = (name, data) => {
    if (data && (
      name === 'product'
      || name === 'company'
      || name === 'plan_type'
    )) {
      if (name === 'company') {
        setValue({
          ...value,
          [`${name}Label`]: data?.label,
          [name]: data?.id,
          currencyCode: data?.currency,
        });
        setCurrency(data?.currency_symbol);
      } else {
        setValue({ ...value, [`${name}Label`]: data?.label, [name]: data?.id });
      }
    } else if (name === 'tenure') {
      setValue({ ...value, [`${name}Label`]: data?.label, [name]: data?.id });
      if (value?.start_date) {
        const endDate = calculateEndDate(new Date(value?.start_date), data?.id);
        setSelectedDate({ ...selectedDate, [name]: data, end_date: endDate });
      }
    } else if (name === 'device_limit') {
      setValue({ ...value, [name]: data ? Number(data) : null });
    } else if (name === 'plan') {
      setValue({
        ...value,
        [`${name}Label`]: data?.label,
        [name]: data?.id,
        pricePerDevice: data?.price_per_device,
        storage: data?.storage,
        device_count: data?.device_limit,
      });
    } else if (name === 'start_date') {
      const startDate = dayjs(data).format('YYYY-MM-DD');
      const endDate = calculateEndDate(new Date(startDate), value.tenure);
      if (startDate >= currentDate) {
        if (startDate !== 'Invalid Date') {
          setValue(
            {
              ...value,
              [name]: startDate,
              end_date: dayjs(endDate).format('YYYY-MM-DD'),
            },
          );
          setSelectedDate({ ...selectedDate, [name]: data, end_date: endDate });
          setErrorMessage('');
        } else {
          setErrorMessage('Invalid Date');
        }
      } else {
        setErrorMessage('Enter a valid date');
      }
    }
  };
  const products = [
    {
      label: 'CMS',
      id: 'cms',
    },
    {
      label: 'Advertiser',
      id: 'advertiser',
    },
  ];

  const planTypes = [
    {
      label: 'Subscription',
      id: 'subscription',
    },
    // {
    //   label: 'Perpetual',
    //   id: 'perpetual',
    // },
  ];

  const terms = [
    {
      label: 'Monthly',
      id: 1,
    },
    {
      label: 'Semi-Annual',
      id: 6,
    },
    {
      label: 'Annual',
      id: 12,
    },
  ];

  // Rent Device functions
  // const deviceRequire = () => {
  //   dispatch(addProductSummary(value));
  //   setModalView({
  //     title: 'Device',
  //     content: <SelectDevice
  //       closeModal={closeModal}
  //     />,
  //   });
  // };

  // const deviceNotRequire = () => {
  //   dispatch(addProductSummary(value));
  //   navigate('../summary');
  // };
  const submit = () => {
    dispatch(addProductSummary({
      ...value,
      devicePrice: pricePerDeviceInMonths || productSummary.devicePrice,
      totalPrice: pricePerDeviceInMonths * value.device_limit || productSummary.totalPrice,
      duration: `${value.start_date} to ${value.end_date}`,
      devices: value.device_limit,
      storage: value.storage,
      currencyCode: value?.currencyCode,
      rentalDevice: value.rentalDevice || [],
      selectedDate,
      currency,
    }));
    navigate('../summary');
  };

  const rentalSubmit = (rentalDevice) => {
    dispatch(addProductSummary({
      ...value,
      devicePrice: pricePerDeviceInMonths || productSummary.devicePrice,
      totalPrice: pricePerDeviceInMonths * value.device_limit || productSummary.totalPrice,
      duration: `${value.start_date} to ${value.end_date}`,
      devices: value.device_limit,
      storage: value.storage,
      currencyCode: value?.currencyCode,
      companyLabel: value?.companyLabel,
      rentalDevice,
      selectedDate,
      currency,
    }));
    navigate('../summary');
  };

  const addRentalDevice = () => {
    let newValue = [
      {
        device_catalog_id: '',
        tenure: '',
        quantity: 1,
        price: 0,
        unitPrice: 0,
      },
    ];
    setButtonStatus('success');
    if (location?.state?.edit && value?.rentalDevice?.length > 0) {
      newValue = value.rentalDevice;
    }
    setModelVew({
      title: t('addDevice'),
      content: <RentalDeviceAdd
        currency={currency}
        currencyCode={value?.currencyCode || 'USD'}
        back={setButtonStatus}
        rentalSubmit={rentalSubmit}
        oldValue={newValue || []}
      />,
    });
  };

  const rentalNotification = () => {
    setButtonStatus('success');
    setModelVew({
      title: t('rentalDevice'),
      content: <RentalDeviceConform
        title={t('rentDeviceQuestion')}
        okFunction={addRentalDevice}
        setOpen={submit}
        buttonLabel={t('yes')}
      />,
    });
  };

  const calculateDevicePrice = () => {
    const currencyToShow = value?.currencyCode || 'USD';
    // Find price per device in list of plans
    const selectedPlan = planData?.find((each) => each?.id === value?.plan);
    const pricePerDevice = selectedPlan?.price_per_device;
    const priceInMonths = pricePerDevice?.find((each) => (
      each?.currency === currencyToShow
    ))?.price_in_months;
    const devicePrice = priceInMonths?.find((each) => each?.month === value?.tenure)?.price;
    setPricePerDeviceInMonths(devicePrice);
  };

  useEffect(() => {
    if (productSummary.selectedDate) {
      setSelectedDate(productSummary.selectedDate || {});
    }
  }, []);

  useEffect(() => {
    if (value?.product
      && value?.company
      && value?.plan
      && value?.plan_type
      && value?.tenure
      && value?.device_limit !== 0
      && value?.start_date
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (value?.company
      && value?.plan
      && value?.tenure
    ) {
      calculateDevicePrice();
    }
  }, [value]);

  useEffect(() => {
    setPlanData(planList.map((plan) => ({
      ...plan,
      id: plan?._id,
      label: plan?.name,
    })));
  }, [planList]);

  useEffect(() => {
    dispatch(fetchPlansWithoutTrail());
    dispatch(fetchCompanyListAll());
    if (!location?.state?.edit) {
      dispatch(addProductSummary({}));
      setValue({});
      setSelectedDate({});
    }
    if (location?.state?.rental) {
      addRentalDevice();
    }
  }, []);

  return (
    <div className="main-container">
      <TopContent
        label={[t('subscriptions'), t('newSubscription')]}
        buttonClass="success-button"
        click={backButton}
      />
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        modalView={modelView}
        closeModal={() => setButtonStatus('')}
      />
      <div className="content-area">
        <div className="subscription-form">
          <h1>{t('productInformation')}</h1>
          <div className="single-row">
            <SelectBox
              label={t('product')}
              name="product"
              required
              value={value.productLabel}
              onchange={handleChange}
              lists={products}
              id="select-product"
              placeholder={t('selectProduct')}
            />
            <SelectBox
              label={t('account')}
              name="company"
              required
              value={value.companyLabel}
              onchange={handleChange}
              lists={companyList}
              id="select-account"
              placeholder={t('selectCompanyName')}
            />
          </div>
          <div className="single-row">
            <SelectBox
              label={t('planType')}
              name="plan_type"
              required
              value={value.plan_typeLabel}
              onchange={handleChange}
              lists={planTypes}
              id="select-term"
              placeholder={t('selectPlanName')}
            />
            <SelectBox
              label={t('planName')}
              name="plan"
              required
              value={value.planLabel}
              onchange={handleChange}
              lists={planData}
              id="select-term"
              placeholder={t('selectPlanName')}
            />
          </div>
          <div className="single-row">
            <SelectBox
              label={t('subscriptionDuration')}
              name="tenure"
              required
              value={value.tenureLabel}
              onchange={handleChange}
              lists={terms}
              id="select-term"
              placeholder={t('selectSubscriptionDuration')}
            />
            <div className="input-with-price">
              <InputIncreaseDecrease
                icon={' '}
                label={t('numberOfDevices')}
                type="number"
                name="device_limit"
                required
                value={value.device_limit ?? 0}
                change={handleChange}
                placeholder={t('enterNumberOfDevices')}
                min={1}
              />
              <p>
                {value.device_limit || 0} * {currency}{pricePerDeviceInMonths?.toFixed(2)
                  || productSummary?.devicePrice}/{t('device')}
                = {currency}{value.device_limit * pricePerDeviceInMonths || productSummary?.totalPrice || 0}
              </p>
            </div>
          </div>
          <div className="single-row">
            <InputDate
              label={t('validFrom')}
              name="start_date"
              required
              value={selectedDate?.start_date}
              change={handleChange}
              disabled={!value?.tenure}
              disablePast
            />
            <InputDate
              label={t('validTill')}
              name="end_date"
              value={selectedDate?.end_date}
              change={handleChange}
              disabled={!value?.tenure}
              readOnly
            />
            <div className="error-message">{errorMessage}</div>
          </div>
          <div className="form-button-group">
            <div className="form-button">
              <Button
                label={t('back')}
                click={backButton}
                classes="default-button"
              />
            </div>
            <div className="form-button">
              <Button
                label={location?.state?.edit ? t('update') : t('create')}
                click={location?.state?.edit ? submit : rentalNotification}
                classes="success-button"
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscription;
