import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import rentalDeviceSummaryHeader from '../../../assets/json/rental-device-summary-header.json';
import TableForm from '../../../Shared/Components/TableForm';
import TopContent from '../../../Shared/Components/TopContent';
import { EditIcon } from '../../../Shared/Components/svgIcon';
import Button from '../../../Shared/Components/Button';
import ModalBox from '../../../Shared/Components/ModalBox';
import {
  addDeviceOrderSummary,
  addDeviceOrder,
} from '../../../redux/slices/DeviceOrderPendingSlice';

import deviceOrderApi from '../../../utils/api/device-order';
import Success from '../../../Shared/Components/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function DeviceOrderSummary() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const deviceOrderSummary = useSelector((state) => state.deviceOrderPending.deviceOrderSummary);
  const [buttonStatus, setButtonStatus] = useState('');
  const [paymentType, setPaymentType] = useState(false);

  const backButton = () => {
    dispatch(addDeviceOrderSummary({}));
    navigate('../');
  };

  const paymentOptionChange = (event) => {
    setPaymentType(event.target.value === 'online');
  };

  const submit = () => {
    setButtonStatus('loading');
    const deviceOrderData = {
      company: deviceOrderSummary?.account,
      currency: deviceOrderSummary?.currencyCode || 'USD',
      create_payment_link: paymentType,
      device_rental_information: deviceOrderSummary?.rentalDevice
        .filter((device) => device.device_catalog_id_label !== undefined)
        .map((device) => ({
          device_catalog_id: device.device_catalog_id,
          tenure: device.tenure,
          quantity: parseInt(device.quantity, 10),
          price: device.unitPrice,
        })),
    };
    deviceOrderApi.addDeviceOrder(deviceOrderData)
      .then((response) => {
        setButtonStatus('success');
        setTimeout(() => {
          setButtonStatus('');
          dispatch(addDeviceOrderSummary({}));
          dispatch(addDeviceOrder(response?.data?.[0]));
          navigate('../');
        }, 3000);
      })
      .catch((err) => {
        setButtonStatus('');
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const deviceOrderSummaryEdit = () => {
    navigate('../add', { state: { edit: true } });
  };

  useEffect(() => {
    if (Object.keys(deviceOrderSummary).length === 0) {
      navigate('../add');
    }
  }, []);

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        modalView={{ content: <Success message={t('deviceRentalCreatedSuccess')} /> }}
        notification
      />
      <TopContent
        label={[t('deviceOrder'), t('newDeviceOrder')]}
        buttonClass="success-button"
        click={backButton}
      />
      <div className="content-area">
        <div className="summary-wrap">
          {
            deviceOrderSummary?.rentalDevice?.length > 0 && (
              <div className="summary-container">
                <div className="summary-header">
                  <h3>{t('deviceRentalSummary')}</h3>
                  <div
                    role="presentation"
                    onClick={() => deviceOrderSummaryEdit(true)}
                    className="edit-container"
                  >
                    <EditIcon color="#9747FF" />
                    <span>{t('edit')}</span>
                  </div>
                </div>
                <div className="summery-sub">Account : {deviceOrderSummary.accountLabel}</div>
                <TableForm
                  header={rentalDeviceSummaryHeader}
                  value={
                    deviceOrderSummary?.rentalDevice.filter((device) => device.device_catalog_id_label !== undefined)
                  }
                  checkBox={false}
                />
                <div className="price">
                  <h2>{t('totalRentalPrice')}</h2>
                  <p>
                    {deviceOrderSummary?.currency}
                    {deviceOrderSummary?.totalPrice}
                  </p>
                </div>
              </div>
            )
          }
          <div className="summary-container">
            <div className="price-summary-wrap">
              <h1>{t('priceSummary')}</h1>
              <div className="price-summary">
                <div className="price">
                  <h2>{t('rentalDevices')} ({deviceOrderSummary?.rentalDevice?.length} {t('rental')} )</h2>
                  <p>
                    {deviceOrderSummary.currency}{deviceOrderSummary?.totalPrice}
                  </p>
                </div>
                <hr />
                <div className="price">
                  <h2><b>{t('totalPrice')}</b></h2>
                  <p>{deviceOrderSummary.currency}{deviceOrderSummary?.totalPrice}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="summary-container">
            <div className="price-summary-wrap">
              <h1>{t('paymentMode')}</h1>
              <div className="price-summary">
                <div className="price">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={paymentOptionChange}
                    defaultValue="offline"
                  >
                    <FormControlLabel
                      value="online"
                      control={<Radio />}
                      label={t('online')}
                      sx={{
                        '& .MuiTypography-root': {
                          fontFamily: 'Poppins',
                        },
                      }}
                    />
                    <FormControlLabel
                      value="offline"
                      control={<Radio />}
                      label={t('offline')}
                      sx={{
                        '& .MuiTypography-root': {
                          fontFamily: 'Poppins',
                        },
                      }}
                    />
                  </RadioGroup>
                </div>
                {
                  paymentType && (
                    <p>{t('onlinePaymentMessage')}</p>
                  )
                }
              </div>
              <div className="form-button-group">
                <div className="form-button">
                  <Button
                    label={t('back')}
                    click={backButton}
                    classes="default-button"
                  />
                </div>
                <div className="form-button">
                  <Button
                    label={buttonStatus === 'loading' ? t('creating') : t('create')}
                    click={submit}
                    classes="success-button"
                    loading={buttonStatus === 'loading'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceOrderSummary;
