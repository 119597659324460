/* eslint no-underscore-dangle: 0 */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import TopContent from '../../../Shared/Components/TopContent';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import { Input, InputInnerSymbol } from '../../../Shared/Components/Form/Input';
import Button from '../../../Shared/Components/Button';

import { fetchPlans } from '../../../redux/slices/PlanSlice';

import plansApi from '../../../utils/api/plans';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function PlansEdit() {
  const { t } = useTranslation();
  const currencies = [
    {
      currency: 'SAR',
      symbol: '﷼',
    },
    {
      currency: 'USD',
      symbol: '$',
    },
    {
      currency: 'EUR',
      symbol: '€',
    },
    {
      currency: 'INR',
      symbol: '₹',
    },
  ];

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { plans } = useSelector((state) => state.plans);
  const [value, setValue] = useState(plans?.find((plan) => plan.id === params?.id));
  const [features, setFeatures] = useState(value?.features || ['', '', '', '']);
  const [pricePerDevice, setPricePerDevice] = useState(value?.price_per_device || []);
  const [buttonStatus, setButtonStatus] = useState('');

  const products = [
    {
      label: 'CMS',
      key: 'cms',
    },
    {
      label: 'Advertiser',
      key: 'advertiser',
    },
  ];

  const planTypes = [
    {
      label: 'Subscription',
      key: 'subscription',
    },
    {
      label: 'Perpetual',
      key: 'perpetual',
    },
  ];

  const fetchPlanDetails = async () => {
    await plansApi.getSinglePlan(params?.id)
      .then((response) => {
        const item = response?.data;
        setValue({
          ...item,
          id: item?._id,
        });
        setFeatures(response?.data?.features);
        setPricePerDevice(response?.data?.price_per_device);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!value) {
      fetchPlanDetails();
    }
  }, [params]);

  const clickFunction = () => {
    navigate('../');
  };

  const handleChange = (name, data) => {
    if (data) {
      if (name === 'product') {
        setValue({ ...value, [name]: data.label, productKey: data.key });
      } else if (name === 'plan_type') {
        setValue({ ...value, [name]: data.label, planKey: data.key });
      }
    }
    if (name !== 'product' && name !== 'plan_type') {
      setValue({ ...value, [name]: data });
    }
  };

  const handleFeatureChange = (index, data) => {
    const updatedFeatures = [...features];
    // Update the value at the specified index
    updatedFeatures[index] = data;
    setFeatures(updatedFeatures);
  };
  const handleChangeDevice = (currency, changeValue, index) => {
    const updatedPricePerDevice = pricePerDevice.map((device) => {
      if (device.currency === currency) {
        // Update the price for the month 1
        const updatedPriceInMonths = device.price_in_months.map((month) => {
          if (month.month === index) {
            return { ...month, price: changeValue ? Number(changeValue) : null };
            // Assuming you want to set the price to 30
          }
          return month;
        });

        return { ...device, price_in_months: updatedPriceInMonths };
      }

      return device;
    });
    setPricePerDevice(updatedPricePerDevice);
  };

  const addRemoveFeature = (action, indexToRemove) => {
    if (action === 'add') {
      setFeatures((prev) => [
        ...prev,
        '',
      ]);
    } else if (action === 'remove') {
      const updatedFeatures = [...features.slice(0, indexToRemove), ...features.slice(indexToRemove + 1)];
      setFeatures(updatedFeatures);
    }
  };

  const submit = async () => {
    let planData = {
      id: params?.id,
      name: value?.name,
      product: value?.productKey || value?.product,
      plan_type: value?.planKey || value?.plan_type,
      description: value?.description,
      price_per_device: pricePerDevice,
      features: features.filter((feature) => feature !== ''),
    };

    if (value?.planKey) {
      planData = { ...planData, plan_type: value?.planKey };
    }

    if (value?.productKey) {
      planData = { ...planData, product: value?.productKey };
    }

    await plansApi.addPlan(planData)
      .then(() => {
        setButtonStatus('success');
        setTimeout(() => {
          setButtonStatus('');
          dispatch(fetchPlans({ page: 1 }));
          navigate('../');
        }, 3000);
      })
      .catch((err) => {
        setButtonStatus('');
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        modalView={{ content: <Success message={t('planUpdatedSuccess')} /> }}
        notification
      />
      <TopContent
        label={[t('plansAndPricing'), value?.name]}
        buttonClass="success-button"
        click={clickFunction}
      />
      <div className="plans-form-wrap">
        <div className="plans-details">
          <div className="single-row">
            <SelectBox
              label={t('product')}
              name="product"
              required
              value={value?.product}
              onchange={handleChange}
              lists={products}
              id="select-plans"
              placeholder={t('selectProduct')}
            />
            <SelectBox
              label={t('planType')}
              name="plan_type"
              required
              value={value?.plan_type}
              onchange={handleChange}
              lists={planTypes}
              id="select-plans"
              placeholder={t('enterPlanType')}
            />
          </div>
          <div className="single-row">
            <Input
              label={t('description')}
              type="text"
              name="description"
              value={value?.description}
              change={handleChange}
              placeholder={t('enterDescription')}
            />
            <Input
              label={t('planName')}
              type="text"
              name="name"
              required
              value={value?.name}
              change={handleChange}
              placeholder={t('enterPlanName')}
            />
          </div>
        </div>
        <div className="features-pricing">
          <div className="features">
            <h1>{t('features')}</h1>
            {features.map((feature, index) => (
              <div className="each-feature">
                <Input
                  label=""
                  type="text"
                  name={index}
                  value={feature}
                  change={handleFeatureChange}
                  placeholder={t('enterPlanFeature')}
                />
                {index === 0
                  && (
                    <div
                      role="presentation"
                      onClick={() => addRemoveFeature('add')}
                      className="add-feature"
                    >
                      {t('add')}
                    </div>
                  )}
                {index > 0
                  && (
                    <div
                      role="presentation"
                      onClick={() => addRemoveFeature('remove', index)}
                      className="remove-feature"
                    >
                      {t('remove')}
                    </div>
                  )}
              </div>
            ))}
          </div>
          <div className="pricing">
            <table className="device">
              <tr>
                <th>{t('deviceDuration')}</th>
                {
                  pricePerDevice?.[0]?.price_in_months?.map((val) => (
                    <td
                      className={val?.month === 12 && 'last-data'}
                    >
                      {val.month} {val.month > 1 ? t('months') : t('month')}
                    </td>
                  ))
                }
              </tr>
              {
                pricePerDevice.map((item, index) => (
                  <tr>
                    <th
                      className={index === pricePerDevice.length - 1 && 'bottom-data'}
                    >
                      {
                        currencies?.map((currency) => (
                          currency.currency === item.currency
                          && (
                            <span>
                              {currency.symbol}&nbsp;
                            </span>
                          )
                        ))
                      }
                      {item.currency}
                    </th>
                    {item?.price_in_months?.map((month) => (
                      <td
                        label="table-data"
                        className={
                          month?.month === 12
                            ? 'last-data'
                            : index === pricePerDevice.length - 1
                              ? 'bottom-data'
                              : index === pricePerDevice.length - 1 && month?.month === 12
                                ? 'last-data bottom-data'
                                : ''
                        }
                      >
                        <div className="input-container">
                          <InputInnerSymbol
                            type="number"
                            name={item?.currency}
                            index={month.month}
                            value={month.price}
                            change={handleChangeDevice}
                            placeholder={0}
                            symbol={currencies.find((each) => each.currency === item.currency)?.symbol}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                ))
              }
            </table>
          </div>
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label={t('back')} click={clickFunction} classes="default-button" />
          </div>
          <div className="form-button">
            <Button
              label={buttonStatus === 'loading' ? t('updating') : t('update')}
              click={submit}
              classes="success-button"
              loading={buttonStatus === 'loading'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlansEdit;
