import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import TopContent from '../../../Shared/Components/TopContent';
import { Input } from '../../../Shared/Components/Form/Input';
import Button from '../../../Shared/Components/Button';

import profile from '../../../assets/images/download.jpg';
import SelectBox from '../../../Shared/Components/Form/SelectBox';

import userApi from '../../../utils/api/users';
import { addUserDetails } from '../../../redux/slices/UsersSlice';
import { fetchRolesListAll } from '../../../redux/slices/RolesSlice';
import ModalBox from '../../../Shared/Components/ModalBox';
import Success from '../../../Shared/Components/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { emailValidation, nameValidation, passwordValidation } from '../../../Shared/Components/Form/Validation';
import { acceptedImageTypes, fileUploadValidation } from '../../../utils/helpers';

function UserAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rolesData = useSelector((state) => state.roles.rolesAllList);
  const [roles, setRoles] = useState([]);
  const [buttonStatus, setButtonStatus] = useState('');
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const [disable, setDisable] = useState(true);
  const [value, setValue] = useState({
    location: [],
  });
  const [image, setImage] = useState();

  useEffect(() => {
    dispatch(fetchRolesListAll());
  }, [navigate]);

  useEffect(() => {
    if (rolesData.length > 0) {
      setRoles(rolesData?.map((val) => ({ ...val, label: val.name })));
    }
  }, [rolesData]);

  const addPlaylist = () => {
    setImage('');
    localStorage.removeItem('value');
    navigate('../');
  };

  useEffect(() => {
    if (
      value.email
      && value.name
      && value.password
      && value.role
      && value.user_name
      && value.location
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  const submit = () => {
    setSubmitButtonClicked(true);
    if (nameValidation(1, 100, value?.name)
      && nameValidation(1, 100, value?.user_name)
      && emailValidation(value?.email)
      && passwordValidation(value?.password)
    ) {
      setButtonStatus('loading');
      const storesId = value?.location?.map((val) => val.id);
      const params = {
        email: value?.email,
        name: value?.name,
        password: value?.password,
        role: value?.role,
        user_name: value?.user_name,
        stores: storesId,
        profile_image: value?.image,
      };
      userApi.addUser(params).then((response) => {
        setButtonStatus('success');
        setTimeout(() => {
          setButtonStatus('');
          dispatch(addUserDetails(response?.data));
          navigate('../');
        }, 3000);
      }).catch((error) => {
        setButtonStatus('');
        dispatch(setErrorNotification(error?.response?.data));
      });
    }
  };

  const onChangePicture = (e) => {
    const maxFileSizeInMB = 1;
    const checkFileValidation = fileUploadValidation(e.target.files[0], maxFileSizeInMB, 'image');
    if (checkFileValidation === '') {
      setValue({ ...value, image: e.target.files[0] });
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      dispatch(setErrorNotification({ message: checkFileValidation }));
    }
  };

  const handleChange = (name, changeValue) => {
    if (changeValue) {
      if (name === 'role') {
        setValue({
          ...value,
          role: changeValue.id,
          roleName: changeValue.label,
        });
      }
    }
    if (name !== 'role') {
      setValue({ ...value, [name]: changeValue });
    }
  };

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        modalView={{ content: <Success message={t('userCreatedSuccess')} /> }}
        notification
      />
      <TopContent
        label={[t('users'), t('createNewUser')]}
        button={false}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <div className="user-form-wrap">
              <div className="profile-upload">
                <label htmlFor="file" className="upload-click">
                  <img src={image || profile} alt="profile" />
                  {!image ? <p>{t('imageFormat')}</p> : <p><span>{t('changeProfile')}</span></p>}
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={onChangePicture}
                  accept={acceptedImageTypes}
                  style={{ display: 'none' }}
                />
              </div>
              <div className="user-form">
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('fullName')}
                    type="text"
                    name="name"
                    required
                    value={value?.name}
                    change={handleChange}
                    placeholder={t('enterName')}
                    error={!nameValidation(1, 100, value?.name) && submitButtonClicked}
                    errorMessage={t('nameValidation')}
                  />
                  <Input
                    icon={' '}
                    label={t('username')}
                    type="text"
                    name="user_name"
                    required
                    value={value?.user_name}
                    change={handleChange}
                    placeholder={t('enterUsername')}
                    error={!nameValidation(1, 100, value?.user_name) && submitButtonClicked}
                    errorMessage={t('userNameValidation')}
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('mailId')}
                    type="text"
                    name="email"
                    required
                    value={value?.email}
                    change={handleChange}
                    placeholder={t('enterMailId')}
                    error={!emailValidation(value?.email) && submitButtonClicked}
                    errorMessage={t('invalidEmail')}
                  />
                  <Input
                    icon={' '}
                    label={t('password')}
                    type="password"
                    name="password"
                    required
                    value={value?.password}
                    change={handleChange}
                    placeholder={t('password')}
                    error={!passwordValidation(value?.password) && submitButtonClicked}
                    errorMessage={t('passwordRequirements')}
                  />
                </div>
                <SelectBox
                  label={t('role')}
                  name="role"
                  required
                  value={value.roleName}
                  onchange={handleChange}
                  lists={roles}
                  id="select-roles"
                  placeholder={t('selectUserRole')}
                />
                <div className="form-button-group">
                  <div className="form-button">
                    <Button label={t('back')} click={addPlaylist} classes="default-button" />
                  </div>
                  <div className="form-button">
                    <Button
                      label={buttonStatus === 'loading' ? t('creating') : t('create')}
                      click={submit}
                      classes={buttonStatus === 'loading' ? 'success-button loading-btn' : 'success-button'}
                      disabled={disable}
                      loading={buttonStatus === 'loading'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAdd;
